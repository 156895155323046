import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { fetchProgramsForSchool } from '../../../api/programs';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

const ProgramSettings = ({ activeSchool }) => {
  const [programData, setProgramData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchProgramsForSchool(activeSchool.id);
        setProgramData(data.programs);
      } catch (error) {
        logErrorToConsole("Error fetching program data", error);
        logErrorToSentryWithContext(error, {
          schoolId: activeSchool.id,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [activeSchool.id]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div className="page-title">Live Programs</div>
      {!programData || programData.length === 0 ? (
        <div className="flex items-center justify-center h-full text-gray-700">
          Looks like you don't have any live programs yet.
        </div>
      ) : (
        <div className="mt-6 overflow-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="sticky top-0 bg-gray-100">
              <tr>
                <th className="py-2 px-4 border-b text-left">Name</th>
                <th className="py-2 px-4 border-b text-left">Max Tuition</th>
                <th className="py-2 px-4 border-b text-left">Program Length</th>
                <th className="py-2 px-4 border-b text-left">Default Down Payment</th>
                <th className="py-2 px-4 border-b text-left">Default Loan Terms</th>
              </tr>
            </thead>
            <tbody>
              {programData.map((program) => (
                <tr key={program.id} className="border-b">
                  <td className="py-2 px-4">{program.program_name}</td>
                  <td className="py-2 px-4">${parseFloat(program.tuition).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                  <td className="py-2 px-4">{program.default_length === 1 ? '1 month' : `${program.default_length} months`}</td>
                  <td className="py-2 px-4">${parseFloat(program.required_down_payment ?? 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                  <td className="py-2 px-4">
                    {program.target_loan_term 
                      ? `${program.target_loan_term} month term`
                      : `$${parseFloat(program.target_monthly_payment ?? 300).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} payment`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProgramSettings;