class TransactionData {
    constructor(data) {
        this.id = data.id;
        this.loanId = data.loan_id;
        this.type = data.type;
        this.date = new Date(data.report_date);
        this.amount = parseFloat(data.amount);
        this.principal = parseFloat(data.principal);
        this.interest = parseFloat(data.interest);
        this.lateCharges = parseFloat(data.late_charges);
        this.colRcFees = parseFloat(data.col_rc_fees);
        this.loanTerm = data.loan_term;

        this.paymentReceived = this.principal + this.interest;
        
        this.fortifyPlatformFee = parseFloat(data.fortify_platform_fee);
        this.fortifyPortfolioManagementFeeSplit = parseFloat(data.post_platform_fee_fortify_split);

        this.fortifyPlatformFeeRemainingBeforeTransaction = parseFloat(data.fortify_platform_fee_remaining_before_transaction);
        this.fortifyPlatformFeeReceived = parseFloat(data.fortify_platform_fee_received);
        this.fortifyPortfolioManagementFeeReceived = parseFloat(data.fortify_portfolio_management_fee_received);
        this.schoolTuitionReceived = parseFloat(data.school_tuition_received);
        this.fortifyPlatformFeeRemainingAfterTransaction = parseFloat(data.fortify_platform_fee_remaining_after_transaction);
        this.studentName = data.student_name;
        this.schoolName = data.school_name;
        this.programName = data.program_name;
        this.disbursementDate = data.disbursement_date ? new Date(data.disbursement_date) : null;

        if (data.type === "DISBURSEMENTS") {
            this.type = "Tuition Disbursement";
        } else if (data.type === "OFEE") {
            this.type = "Origination Fee";
        } else if (data.type === "DISB CANCEL") {
            this.type = "Tuition Refund";
        } else if (data.type === "OFEE CANCEL") {
            this.type = "Origination Fee Refund";
        } else if (data.type === "CAP INT") {
            this.type = "Capitalize Interest";
        } else if (this.amount < 0) {
            this.type = "Reversal";
        } else if (data.type === "PMT-REG-APP") {
            this.type = "Automatic ACH";
        } else {
            this.type = "Manual Payment";
        }
    }
}

class DisbursementData {
    constructor(data) {
        this.id = data.id;
        this.loanId = data.loan_id;
        this.schoolId = data.school_id;
        this.schoolName = data.school_name;
        this.disbursementDate = new Date(data.disbursement_date);
        this.amountDisbursed = parseFloat(data.amount_disbursed);
        this.report = data.report;
    }
}

class UpcomingPaymentData {
    constructor(data) {
        this.loanId = data.loan_id;
        this.studentName = data.student_name;
        this.schoolName = data.school_name;
        this.programName = data.program_name;
        this.email = data.email;
        this.phone = data.phone;

        this.fortifyScore = data.fortify_score;
        this.riskAssessmentPurchasedBySchool = data.risk_assessment_purchased_by_school;

        if (!data.risk_assessment_purchased_by_school || data.fortify_tier === null) {
            this.fortifyTier = "?";
        } else if (data.fortify_tier === -1) {
            this.fortifyTier = -1;
        } else {
            this.fortifyTier = data.fortify_tier;
        }

        this.upcomingPaymentDate = data.upcoming_payment_date ? new Date(data.upcoming_payment_date) : null;
        this.earliestOwedPaymentDate = data.next_payment_date ? new Date(data.next_payment_date) : null;
        this.monthlyPayment = parseFloat(data.monthly_payment);

        this.isFirstPayment = data.is_first_payment;
        this.autopayStatus = data.autopay_status || "N";
        this.daysDelinquent = data.days_delinquent || 0;
        this.pastDueAmount = data.past_due_amount ? parseFloat(data.past_due_amount) : 0;
        this.loanCounselingScheduledFor = data.loan_counseling_scheduled_for ? new Date(data.loan_counseling_scheduled_for) : null;
        this.loanCounselingCompletedOn = data.loan_counseling_completed_on ? new Date(data.loan_counseling_completed_on) : null;

        this.registrationDate = data.registration_date ? new Date(data.registration_date) : null;
        this.lastLogin = data.last_login ? new Date(data.last_login) : null;
        this.lastCallAttemptDate = data.last_attempt_date ? new Date(data.last_attempt_date) : null;
        this.lastCallDate = data.last_contact_date ? new Date(data.last_contact_date) : null;

        this.gradDate = new Date(data.grad_date);
        this.currentlyInSchool = data.currently_in_school;

        this.estimatedSchoolTuition = parseFloat(data.estimated_school_tuition);
    }
}

class PortfolioReportData {
    constructor(data) {
        this.loanId = data.loan_id;
        this.studentName = data.student_name;
        this.schoolName = data.school_name;
        this.programName = data.program_name;
        this.loanApplicationId = data.loan_application_id;
        this.email = data.email;
        this.phone = data.phone;
        this.startDate = new Date(data.start_date);
        this.gradDate = new Date(data.grad_date);
        this.currentlyInSchool = data.currently_in_school;

        this.fortifyScore = data.fortify_score;
        this.riskAssessmentPurchasedBySchool = data.risk_assessment_purchased_by_school;

        if (!data.risk_assessment_purchased_by_school || data.fortify_tier === null) {
            this.fortifyTier = "?";
        } else if (data.fortify_tier === -1) {
            this.fortifyTier = -1;
        } else {
            this.fortifyTier = data.fortify_tier;
        }

        this.firstPaymentDueDate = data.first_payment_due_date ? new Date(data.first_payment_due_date) : null;
        this.earliestOwedPaymentDate = data.next_payment_date ? new Date(data.next_payment_date) : null;

        this.tuitionFinanced = parseFloat(data.tuition_financed);
        this.borrowingAmount = parseFloat(data.borrowing_amount);
        this.interestRate = parseFloat(data.interest_rate);
        this.loanTerm = data.loan_term;
        this.monthlyPayment = parseFloat(data.monthly_payment);
        this.autopayStatus = data.autopay_status || "N";
        this.originationScheduledFor = data.origination_scheduled_for ? new Date(data.origination_scheduled_for) : null;
        this.originatedOn = data.originated_on ? new Date(data.originated_on) : null;
        this.boardedToServicerOn = data.boarded_to_servicer_on ? new Date(data.boarded_to_servicer_on) : null;
        this.loanCounselingScheduledFor = data.loan_counseling_scheduled_for ? new Date(data.loan_counseling_scheduled_for) : null;
        this.loanCounselingCompletedOn = data.loan_counseling_completed_on ? new Date(data.loan_counseling_completed_on) : null;
        this.currentPrincipalBalance = data.current_principal_balance ? parseFloat(data.current_principal_balance) : 0;
        this.currentInterestBalance = data.current_interest_balance ? parseFloat(data.current_interest_balance) : 0;
        this.principalPaidToDate = data.principal_paid_to_date ? parseFloat(data.principal_paid_to_date) : 0;
        this.interestPaidToDate = data.interest_paid_to_date ? parseFloat(data.interest_paid_to_date) : 0;
        this.daysDelinquent = data.days_delinquent || 0;
        this.daysMostDelinquent = data.days_most_delinquent || 0;
        this.pastDueAmount = data.past_due_amount ? parseFloat(data.past_due_amount) : 0;

        if (this.currentPrincipalBalance === null || this.currentInterestBalance === null) {
            this.currentOutstandingLoanBalance = this.borrowingAmount;
        } else {
            this.currentOutstandingLoanBalance = this.currentPrincipalBalance + this.currentInterestBalance;
        }

        this.totalPaymentsMade = this.principalPaidToDate + this.interestPaidToDate;

        this.fortifyPlatformFee = parseFloat(data.fortify_platform_fee);
        this.fortifyPlatformFeeSplit = parseFloat(data.pre_platform_fee_fortify_split);
        this.fortifyPlatformFeeReceived = parseFloat(data.fortify_platform_fee_received);

        this.fortifyPortfolioManagementFeeSplit = parseFloat(data.post_platform_fee_fortify_split);
        this.fortifyPortfolioManagementFeeReceived = parseFloat(data.fortify_portfolio_management_fee_received);

        this.schoolTuitionReceived = parseFloat(data.school_tuition_received);
        this.schoolTuitionDisbursed = parseFloat(data.school_tuition_disbursed);

        this.registrationDate = data.registration_date ? new Date(data.registration_date) : null;
        this.lastLogin = data.last_login ? new Date(data.last_login) : null;

        this.lastForbearanceBeginDate = data.last_forbearance_begin_date ? new Date(data.last_forbearance_begin_date) : null;
        this.lastForbearanceEndDate = data.last_forbearance_end_date ? new Date(data.last_forbearance_end_date) : null;
        this.totalForbearanceMonths = data.total_forbearance_months ? parseInt(data.total_forbearance_months) : 0;

        this.lastCallAttemptDate = data.last_attempt_date ? new Date(data.last_attempt_date) : null;
        this.lastCallDate = data.last_contact_date ? new Date(data.last_contact_date) : null;
    }
}

class DownPaymentData {
    constructor(data) {
        this.loanId = data.loan_id;
        this.loanApplicationId = data.loan_application_id;

        this.studentName = data.student_name;
        this.schoolName = data.school_name;
        this.programName = data.program_name;
        this.amount = parseFloat(data.amount);

        this.receivedOn = data.received_on ? new Date(data.received_on) : null;
        this.executedOn = data.executed_on ? new Date(data.executed_on) : null;
        this.cancelledAt = data.cancelled_at ? new Date(data.cancelled_at) : null;
    }
}

class UpcomingDisbursementData {
    constructor(data) {
        this.nextDisbursementDate = new Date(data.next_disbursement_date);
        this.confirmedTuitionToBeDisbursed = parseFloat(data.confirmed_tuition_to_be_disbursed);
        this.potentialTuitionReceivable = parseFloat(data.potential_tuition_receivable);
    }
}

export { TransactionData, DisbursementData, UpcomingPaymentData, PortfolioReportData, DownPaymentData, UpcomingDisbursementData };