import React, { useState, useEffect } from 'react';
import HomeStats from './HomeStats';
import ActionItems from './ActionItems';
import LoanApplicationData from '../../../models/LoanApplicationData';
import { CircularProgress } from '@mui/material';
import { fetchHomepageForSchool } from '../../../api/applications';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

const Dashboard = ({ activeSchool, refreshDataTrigger }) => {
  const [homepageData, setHomepageData] = useState(null);
  const [homepageDataIsLoading, setHomepageDataIsLoading] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('all_time');

  const getPeriodDates = () => {
    const fortifyLaunch = '2024-06-01'
    
    const now = new Date()
    const centralTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/Chicago' }))
    
    const getFirstDayOfMonth = (date) => {
      return new Date(date.getFullYear(), date.getMonth(), 1)
    }
    
    const getFirstDayOfNextMonth = (date) => {
      const nextMonth = date.getMonth() === 11 ? 0 : date.getMonth() + 1
      const nextYear = date.getMonth() === 11 ? date.getFullYear() + 1 : date.getFullYear()
      return new Date(nextYear, nextMonth, 1)
    }
    
    const getFirstDayOfLastMonth = (date) => {
      const lastMonth = date.getMonth() === 0 ? 11 : date.getMonth() - 1
      const lastYear = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear()
      return new Date(lastYear, lastMonth, 1)
    }
    
    const formatDate = (date) => {
      return date.toISOString().split('T')[0]
    }
    
    const tomorrow = new Date(centralTime)
    tomorrow.setDate(centralTime.getDate() + 1)
    
    const dateRanges = {
      all_time: {
        start: fortifyLaunch,
        end: formatDate(tomorrow)
      },
      current_month: {
        start: formatDate(getFirstDayOfMonth(centralTime)),
        end: formatDate(getFirstDayOfNextMonth(centralTime))
      },
      last_month: {
        start: formatDate(getFirstDayOfLastMonth(centralTime)),
        end: formatDate(getFirstDayOfMonth(centralTime))
      }
    }

    const range = dateRanges[selectedPeriod] || dateRanges.all_time
    return `${range.start}_${range.end}`
  };

  const handlePeriodChange = (newPeriod) => {
    setSelectedPeriod(newPeriod);
  };

  const fetchHomepageData = async () => {
    if (activeSchool && activeSchool.id) {
      setHomepageDataIsLoading(true);
      try {
        const period = getPeriodDates();
        const data = await fetchHomepageForSchool(activeSchool.id, period);
        setHomepageData(data);
      } catch (error) {
        logErrorToConsole("Error fetching homepage data", error);
        logErrorToSentryWithContext(error, {
          schoolId: activeSchool.id,
        });
      } finally {
        setHomepageDataIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchHomepageData();
  }, [activeSchool, refreshDataTrigger, selectedPeriod]);

  const { 
    stats: statsData,
    students_to_certify: studentsToCertify
  } = homepageData || {};

  const loanApplications = studentsToCertify ? studentsToCertify.map(result => new LoanApplicationData(result)) : [];
  const sortedLoanApplications = loanApplications.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

  if (homepageDataIsLoading || statsData === undefined || studentsToCertify === undefined) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="home-stats-container">
          <HomeStats statsData={statsData} selectedPeriod={selectedPeriod} onPeriodChange={handlePeriodChange} />
        </div>
        <div className="action-items-container">
          <ActionItems 
            applicationsToCertify={sortedLoanApplications} 
            onActionSuccess={fetchHomepageData} 
            activeSchool={activeSchool} 
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;