class SchoolData {
    constructor(data) {
      this.id = data.id;
      this.school_name = data.school_name;
      this.school_street = data.school_street;
      this.school_street_line_two = data.school_street_line_two;
      this.school_city = data.school_city;
      this.school_state = data.school_state;
      this.school_zip = data.school_zip;
      this.school_logo = data.school_logo;
      this.is_collecting_down_payment_with_fortify = data.is_collecting_down_payment_with_fortify;
      this.custom_application_link = data.custom_application_link;
      this.should_show_repayment_period_at_cert = data.should_show_repayment_period_at_cert;

      this.has_certifications_access = data.has_certifications_access;
      this.has_risk_management_access = data.has_risk_management_access;
      this.has_repayments_access = data.has_repayments_access;
      this.has_accounting_access = data.has_accounting_access;
      this.has_bank_account_access = data.has_bank_account_access;
      this.has_user_management_access = data.has_user_management_access;
    }
}

export default SchoolData;