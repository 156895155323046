import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, formatDate, formatPercent } from '../../utils/formatters';
import { useTableSort } from '../../hooks/useTableSort';

const TransactionTable = ({ 
  transactionData, 
  showStudentInfo = true, 
  enableHoverAndClick = true,
  initialSortField = 'date',
  initialSortDirection = 'desc'
}) => {
  const navigate = useNavigate();
  const { handleSort, getSortedData, renderSortIndicator } = useTableSort(initialSortField, initialSortDirection);

  const handleRowClick = (loanId) => {
    if (enableHoverAndClick) {
      navigate(`/loans/${loanId}`);
    }
  };

  const shouldHideLoanLevelFees = (
    transactionData.length > 0
    && (
      Number.isNaN(transactionData[0].fortifyPlatformFee) 
      || transactionData[0].fortifyPlatformFee === undefined
      || Number.isNaN(transactionData[0].fortifyPortfolioManagementFeeSplit)
      || transactionData[0].fortifyPortfolioManagementFeeSplit === undefined
    )
  );

  return (
    <table className="loan-table">
      <thead className="sticky top-0 bg-gray-100">
        <tr className="h-12">
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('date')}>
            Transaction Date {renderSortIndicator('date')}
          </th>
          {showStudentInfo && (
            <>
              <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('studentName')}>
                Student {renderSortIndicator('studentName')}
              </th>
              <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('programName')}>
                Program {renderSortIndicator('programName')}
              </th>
            </>
          )}
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('type')}>
            Type {renderSortIndicator('type')}
          </th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('amount')}>
            Amount {renderSortIndicator('amount')}
          </th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('principal')}>
            Principal {renderSortIndicator('principal')}
          </th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('interest')}>
            Interest {renderSortIndicator('interest')}
          </th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lateCharges')}>
            Late Charges {renderSortIndicator('lateCharges')}
          </th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('colRcFees')}>
            Collection/Recovery Fees {renderSortIndicator('colRcFees')}
          </th>

          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('paymentReceived')}>
            Total Payment Received {renderSortIndicator('paymentReceived')}
          </th>
          {!shouldHideLoanLevelFees && (
            <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPlatformFee')}>
              Total Fortify Platform Fee {renderSortIndicator('fortifyPlatformFee')}
            </th>
          )}
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPlatformFeeRemainingBeforeTransaction')}>
            Fortify Platform Fee Remaining Before Transaction {renderSortIndicator('fortifyPlatformFeeRemainingBeforeTransaction')}
          </th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPlatformFeeReceived')}>
            Fortify Platform Fees Paid From Payment {renderSortIndicator('fortifyPlatformFeeReceived')}
          </th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPlatformFeeRemainingAfterTransaction')}>
            Fortify Platform Fee Remaining After Transaction {renderSortIndicator('fortifyPlatformFeeRemainingAfterTransaction')}
          </th>
          {!shouldHideLoanLevelFees && (
            <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPortfolioManagementFeeSplit')}>
              Fortify Ongoing Fee {renderSortIndicator('fortifyPortfolioManagementFeeSplit')}
            </th>
          )}
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPortfolioManagementFeeReceived')}>
            Fortify Ongoing Fees Paid From Payment {renderSortIndicator('fortifyPortfolioManagementFeeReceived')}
          </th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('schoolTuitionReceived')}>
            Tuition Received From Payment {renderSortIndicator('schoolTuitionReceived')}
          </th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('disbursementDate')}>
            Disbursement Date {renderSortIndicator('disbursementDate')}
          </th>
        </tr>
      </thead>
      <tbody>
        {getSortedData(transactionData).map((item, index) => (
          <tr 
            key={index} 
            className={`border-b h-12 ${enableHoverAndClick ? 'cursor-pointer hover:bg-gray-100' : ''}`} 
            onClick={() => handleRowClick(item.loanId)}
          >
            <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.date)}</td>
            {showStudentInfo && (
              <>
                <td className="py-2 px-4 whitespace-nowrap">{item.studentName}</td>
                <td className="py-2 px-4 whitespace-nowrap">{item.programName}</td>
              </>
            )}
            <td className="py-2 px-4 whitespace-nowrap">{item.type}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.amount)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.principal)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.interest)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.lateCharges)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.colRcFees)}</td>

            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.paymentReceived)}</td>
            {!shouldHideLoanLevelFees && <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFee)}</td>}
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFeeRemainingBeforeTransaction)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFeeReceived)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFeeRemainingAfterTransaction)}</td>
            {!shouldHideLoanLevelFees && <td className="py-2 px-4 whitespace-nowrap">{formatPercent(item.fortifyPortfolioManagementFeeSplit)}</td>}
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPortfolioManagementFeeReceived)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.schoolTuitionReceived)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.disbursementDate)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TransactionTable;
