import React, { useState } from 'react';
import CertificationModal from '../actions/CertificationModal';
import ArchiveModal from '../actions/ArchiveModal';

const CertificationAction = ({ application, onActionSuccess, activeSchool }) => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isCertificationModalOpen, setIsCertificationModalOpen] = useState(false);

  const handleOpenArchiveModal = () => {
    setIsArchiveModalOpen(true);
  };

  const handleCloseArchiveModal = () => {
    setIsArchiveModalOpen(false);
  };

  const handleOpenCertificationModal = () => {
    setIsCertificationModalOpen(true);
  };

  const handleCloseCertificationModal = () => {
    setIsCertificationModalOpen(false);
  };

  const name = `${application.student.account.firstName} ${application.student.account.lastName}`;

  const formattedStartDate = !application.startDate ? "" : (
    new Date(application.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })
  )

  return (
    <div className="action-card">
      <div className="certification-action-content">
        <div className="certification-action-text">
          <span className="font-bold">{name}</span>'s application to {application.program.programName} needs to be certified. <span className="text-[#68867d]">Start date: {formattedStartDate}</span>.
        </div>
        <div className="certification-action-buttons">
          <button className="outlined-button w-full py-2 rounded-lg" onClick={handleOpenArchiveModal}>Archive</button>
          <button className="filled-button w-full py-2 rounded-lg" onClick={handleOpenCertificationModal}>Certify</button>
        </div>
      </div>

      <ArchiveModal
        open={isArchiveModalOpen}
        onClose={handleCloseArchiveModal}
        applicationData={application}
        onActionSuccess={onActionSuccess}
      />

      <CertificationModal
        open={isCertificationModalOpen}
        onClose={handleCloseCertificationModal}
        applicationData={application}
        setApplicationData={onActionSuccess} // TODO: just refreshes the homepage data, but ideally we re-open the modal
        onActionSuccess={onActionSuccess}
        activeSchool={activeSchool}
      />
    </div>
  );
};

export default CertificationAction;