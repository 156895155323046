import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LoanApplicationData from '../../models/LoanApplicationData';
import { Snackbar, Button, CircularProgress } from '@mui/material';
import { searchLoanApplicationsAtSchool, fetchLoanApplicationById } from '../../api/applications';
import debounce from 'lodash.debounce';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const bugReportIcon = "/topbarIcons/bug-report.svg"
const hoverBugReportIcon = "/topbarIcons/bug-report-hover.svg"

const contactIcon = "/topbarIcons/contact-us.svg"
const hoverContactIcon = "/topbarIcons/contact-us-hover.svg"

const settingsIcon = "/topbarIcons/settings.svg"
const hoverSettingsIcon = "/topbarIcons/settings-hover.svg"

const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);

const TopBar = ({ activeSchool, selectedTab, onSelectApplication }) => {
    const navigate = useNavigate();

    const [isLoadingApplication, setIsLoadingApplication] = useState(false);

    const [isBugReportHovered, setIsBugReportHovered] = useState(false);
    const isBugReportActive = selectedTab === 'report-a-bug';

    const [isSettingsHovered, setIsSettingsHovered] = useState(false);
    const isSettingsActive = selectedTab === 'settings';

    const [isContactHovered, setIsContactHovered] = useState(false);
    const isContactActive = selectedTab === 'contact-us';

    const [searchQuery, setSearchQuery] = useState('');

    const [searchResults, setSearchResults] = useState(null);
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);

    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const searchInputRef = useRef(null);
    const searchResultsRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchInputRef.current &&
                !searchInputRef.current.contains(event.target) &&
                searchResultsRef.current &&
                !searchResultsRef.current.contains(event.target)
            ) {
                setIsSearchResultsVisible(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
                event.preventDefault();
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleCopyLink = () => {
        const applicationLink = activeSchool.custom_application_link
            ? `https://apply.fortifyedu.com/${activeSchool.custom_application_link}`
            : `https://apply.fortifyedu.com?school=${activeSchool.id}`;

        navigator.clipboard.writeText(applicationLink);
        setIsSnackbarOpen(true);
    };

    const handleSettingsClick = () => {
        navigate('/settings/team-members');
    };

    const handleContactClick = () => {
        navigate('/contact-us');
    };

    const handleBugReportClick = () => {
        navigate('/report-a-bug');
    };

    const fetchAndUpdateSearchData = async (query) => {
        setSearchResults(null);

        if (activeSchool && activeSchool.id && query.length > 0) {
            try {
                const data = await searchLoanApplicationsAtSchool(query, activeSchool.id);
                const matches = data?.matches || [];
                setSearchResults(matches);
            } catch (error) {
                logErrorToConsole("Error fetching search data", error);
                logErrorToSentryWithContext(error, {
                    query,
                    schoolId: activeSchool.id,
                });
            }
        }
    };

    const debouncedFetchAndUpdateSearchData = useCallback(debounce(fetchAndUpdateSearchData, 300), [activeSchool]);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setIsSearchResultsVisible(!query ? false : true);
        debouncedFetchAndUpdateSearchData(query);
    };

    const handleSelectApplication = (result, event) => {
        event.stopPropagation();
        if (result.school_stage === 'funded') {
            navigate(`/loans/${result.loan_id}`);
        } else {
            setIsLoadingApplication(true);
            fetchLoanApplicationById(activeSchool.id, result.id)
                .then(applicationData => {
                    const formattedApplicationData = new LoanApplicationData(applicationData);
                    onSelectApplication(formattedApplicationData);
                })
                .catch(error => {
                    console.error("Error fetching application data", error);
                })
                .finally(() => {
                    setIsLoadingApplication(false);
                });
        }
        setSearchQuery('');
        setSearchResults(null);
    };

    const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
    };

    const getShortcutText = () => {
        return isMac ? '⌘F' : 'Ctrl+F';
    };

    return (
        <div className="flex items-center justify-between pt-6 pb-4 relative">
            <div className="relative flex-grow">
                <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                        <img src="/search.svg" alt="Search" className="w-5 h-5" />
                    </span>
                    <input
                        type="text"
                        placeholder={window.innerWidth < 900 ? "Search" : "Search for a student"}
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onBlur={() => {
                            setTimeout(() => {
                                setIsSearchResultsVisible(false);
                                setSearchQuery('');
                                setSearchResults(null);
                            }, 200);
                        }}
                        className="pl-12 pr-20 p-3 rounded-lg border border-gray-300 hover:bg-gray-100 focus:bg-gray-100 w-full"
                        ref={(el) => {
                            searchInputRef.current = el;
                            inputRef.current = el;
                        }}
                    />
                    <span className={`absolute inset-y-0 right-0 flex items-center pr-6 text-gray-400 pointer-events-none ${window.innerWidth < 1000 ? 'hidden' : ''}`}>
                        {getShortcutText()}
                    </span>
                </div>
                {searchQuery.length > 0 && isSearchResultsVisible && (
                    <div ref={searchResultsRef} className="absolute bg-white border border-gray-300 w-full mt-1 rounded-lg shadow-lg z-10 overflow-hidden">
                        {searchResults === null ? (
                            <div className="min-h-[100px] flex items-center justify-center text-gray-500">
                                <CircularProgress size={20} />
                            </div>
                        ) : (
                            searchResults.length > 0 ? (
                                <ul className="max-h-60 overflow-y-auto">
                                    {searchResults.map(result => (
                                        <li
                                            key={result.id}
                                            className={`p-3 cursor-pointer ${
                                                result.school_stage === 'funded'
                                                    ? 'hover:bg-hover-green/80 bg-hover-green/50'
                                                    : 'hover:bg-[#f0f0f0]'
                                            }`}
                                            onClick={(event) => handleSelectApplication(result, event)}
                                        >
                                            {result.student_name} - {result.program_name}
                                            {result.school_stage === 'funded' && ' (active loan)'}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="min-h-[100px] flex items-center justify-center text-gray-500">
                                    No results found
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>

            <div className="flex items-center space-x-4 ml-4">
                <button
                    className={`p-2 rounded-lg ${isBugReportActive ? 'bg-[#eed9f6]' : ''}`}
                    onClick={handleBugReportClick}
                    onMouseEnter={() => setIsBugReportHovered(true)}
                    onMouseLeave={() => setIsBugReportHovered(false)}
                >
                    <img
                        src={(!isBugReportActive && isBugReportHovered) ? hoverBugReportIcon : bugReportIcon}
                        alt="Bug Report"
                        className="page-icon"
                    />
                </button>

                <button
                    className={`p-2 rounded-lg ${isContactActive ? 'bg-[#eed9f6]' : ''}`}
                    onClick={handleContactClick}
                    onMouseEnter={() => setIsContactHovered(true)}
                    onMouseLeave={() => setIsContactHovered(false)}
                >
                    <img
                        src={(!isContactActive && isContactHovered) ? hoverContactIcon : contactIcon}
                        alt="Contact"
                        className="page-icon"
                    />
                </button>

                <button
                    className={`p-2 rounded-lg ${isSettingsActive ? 'bg-[#eed9f6]' : ''}`}
                    onClick={handleSettingsClick}
                    onMouseEnter={() => setIsSettingsHovered(true)}
                    onMouseLeave={() => setIsSettingsHovered(false)}
                >
                    <img
                        src={(!isSettingsActive && isSettingsHovered) ? hoverSettingsIcon : settingsIcon}
                        alt="Settings"
                        className="page-icon"
                    />
                </button>

                <button className="app-link-button py-2 px-4 justify-center bg-pink hover:bg-[#7c229d] text-sm lg:text-base text-white font-semibold rounded-md flex items-center" onClick={handleCopyLink}>
                    <img src="/url.svg" alt="Application Link" className="w-4 h-4 lg:w-5 lg:h-5 mr-2" />
                    App Link
                </button>
            </div>

            <Snackbar
                open={isSnackbarOpen}
                onClose={handleSnackbarClose}
                message="Your school's Fortify application link was copied to your clipboard!"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2500}
                action={
                    <Button color="inherit" size="small" onClick={handleSnackbarClose} style={{ padding: '4px', minWidth: 'unset' }}>
                        <img src="/close_white.svg" alt="Close" className="w-5 h-5" />
                    </Button>
                }
                ContentProps={{
                    style: {
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        padding: '4px 24px',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        backgroundColor: '#6E6E6F',
                        borderRadius: '0.75rem',
                    },
                }}
                style={{
                    position: 'absolute',
                    top: '100%',
                    right: 0,
                }}
            />
        </div>
    );
};

export default TopBar;
