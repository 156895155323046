import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTransactionsForSchool } from '../../api/applications';
import { TransactionData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrencyForCSV, formatDateForCSV, formatPercent } from '../../utils/formatters';
import ExportToCSV from '../shared/ExportToCSV';
import TransactionTable from '../shared/TransactionTable';

const TransactionHistory = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTransactionsForSchool(activeSchool.id);
        const formattedData = data.map(item => new TransactionData(item));
        const sortedData = formattedData.sort((a, b) => b.date - a.date);
        setTransactionData(sortedData);
      } catch (error) {
        console.error("Error fetching transaction data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!activeSchool.has_accounting_access) {
      navigate('/dashboard');
    } else {
      fetchData();
    }
  }, [activeSchool.id, activeSchool.has_accounting_access, navigate]);

  const exportToCSV = () => {
    const headers = [
      "Transaction Date",
      "Student",
      "Program",
      "Type",
      "Amount",
      "Principal",
      "Interest",
      "Late Charges",
      "Collection/Recovery Fees",

      "Total Payment Received",
      "Total Fortify Platform Fee",
      "Fortify Platform Fee Remaining Before Transaction",
      "Fortify Platform Fees Paid From Payment",
      "Fortify Platform Fee Remaining After Transaction",
      "Fortify Ongoing Fee",
      "Fortify Ongoing Fees Paid From Payment",
      "Tuition Received From Payment",
      "Disbursement Date",
    ];
  
    const csvData = transactionData.map(item => [
      formatDateForCSV(item.date),
      item.studentName,
      item.programName,
      item.type,
      formatCurrencyForCSV(item.amount),
      formatCurrencyForCSV(item.principal),
      formatCurrencyForCSV(item.interest),
      formatCurrencyForCSV(item.lateCharges),
      formatCurrencyForCSV(item.colRcFees),

      formatCurrencyForCSV(item.paymentReceived),
      formatCurrencyForCSV(item.fortifyPlatformFee),
      formatCurrencyForCSV(item.fortifyPlatformFeeRemainingBeforeTransaction),
      formatCurrencyForCSV(item.fortifyPlatformFeeReceived),
      formatCurrencyForCSV(item.fortifyPlatformFeeRemainingAfterTransaction),
      formatPercent(item.fortifyPortfolioManagementFeeSplit),
      formatCurrencyForCSV(item.fortifyPortfolioManagementFeeReceived),
      formatCurrencyForCSV(item.schoolTuitionReceived),
      formatDateForCSV(item.disbursementDate),
    ]);
  
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      const today = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
      link.setAttribute('download', `Fortify Transaction History ${today}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (transactionData.length === 0) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Transaction History</div>
        <hr className='my-2 line-divider'/>
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              You don't have any payments yet.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Transaction History</div>
        <ExportToCSV exportToCSV={exportToCSV} />
      </div>
      <hr className='mt-2 line-divider'/>
      <div className="flex-grow overflow-hidden loan-table-wrapper">
        <div className="mt-6 loan-table-container">
          <TransactionTable transactionData={transactionData} enableHoverAndClick={true} />
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
