import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchLoanPortfolioForSchool } from '../../api/applications';
import { PortfolioReportData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrency, formatCurrencyForCSV, formatDate, formatDateForCSV, formatPercentToTwoDecimals, formatPercent, formatPhone } from '../../utils/formatters';
import StatusPill from '../shared/StatusPill';
import ExportToCSV from '../shared/ExportToCSV';
import { useTableSort } from '../../hooks/useTableSort';

const Forbearance = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [portfolioData, setPortfolioData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { handleSort, getSortedData, renderSortIndicator } = useTableSort();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchLoanPortfolioForSchool(activeSchool.id);
        const formattedData = data.map(item => new PortfolioReportData(item));
        const today = new Date();
        const forbearanceData = formattedData.filter(item => 
          item.lastForbearanceEndDate !== null && 
          item.lastForbearanceEndDate >= today
        );
        setPortfolioData(forbearanceData);
      } catch (error) {
        console.error("Error fetching loan portfolio data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!activeSchool.has_repayments_access) {
      navigate('/dashboard');
    } else {
      fetchData();
    }
  }, [activeSchool.id, activeSchool.has_repayments_access, navigate]);

  const getFortifyTierDisplay = (item) => {
    if (!item.riskAssessmentPurchasedBySchool || item.fortifyTier === null) {
      return "?";
    }
    if (item.fortifyTier === -1) {
      return "Frozen";
    }
    return item.fortifyTier;
  };

  const exportToCSV = () => {
    const headers = [
      // Student details
      "Student",
      "Program",
      "Fortify Tier",

      // Forbearance details
      "Last Forbearance Start Date",
      "Last Forbearance End Date",
      "Total Forbearance Months",

      // Delinquency details
      "Days Delinquent",
      "Past Due Amount",
      "Earliest Owed Payment Date",

      // Repayment details
      "In School",
      "Graduation Date",
      "Autopay Enabled",
      "Logged into Payment Portal",
      "Last Login To Payment Portal",
      "Last Call Attempt by ZuntaFi",
      "Last Conversation with ZuntaFi",
      "Loan Counseling Completed",
      "Loan Counseling Completed On",

      // Contact details
      "Email",
      "Phone",

      // Loan details
      "Tuition Financed",
      "Final Loan Amount",
      "Interest Rate",
      "Loan Term",
      "Monthly Payment",
      "Origination Scheduled For",
      "Originated On",
      "First Payment Due Date",

      // Accounting details
      "Current Outstanding Loan Balance",
      "Total Payments Made",
      "Total Fortify Platform Fee",
      "Fortify Ongoing Fee",
      "Fortify Platform Fees Paid",
      "Fortify Ongoing Fees Paid",
      "Tuition Collected",
    ];
  
    const csvData = portfolioData.map(item => [
      // Student details
      item.studentName,
      item.programName,
      getFortifyTierDisplay(item),

      // Forbearance details
      formatDateForCSV(item.lastForbearanceBeginDate),
      formatDateForCSV(item.lastForbearanceEndDate),
      item.totalForbearanceMonths,

      // Delinquency details
      item.daysDelinquent,
      formatCurrencyForCSV(item.pastDueAmount),
      formatDateForCSV(item.earliestOwedPaymentDate),

      // Repayment details
      item.currentlyInSchool ? "Y" : "N",
      formatDateForCSV(item.gradDate),
      item.autopayStatus === "Y" ? "Y" : "N",
      item.lastLogin !== null ? "Y" : "N",
      formatDateForCSV(item.lastLogin),
      formatDateForCSV(item.lastCallAttemptDate),
      formatDateForCSV(item.lastCallDate),
      item.loanCounselingCompletedOn !== null ? "Y" : "N",
      formatDateForCSV(item.loanCounselingCompletedOn),

      // Contact details
      item.email,
      item.phone,

      // Loan details
      formatCurrencyForCSV(item.tuitionFinanced),
      formatCurrencyForCSV(item.borrowingAmount),
      formatPercentToTwoDecimals(item.interestRate),
      item.loanTerm,
      formatCurrencyForCSV(item.monthlyPayment),
      formatDateForCSV(item.originationScheduledFor),
      formatDateForCSV(item.originatedOn),
      formatDateForCSV(item.firstPaymentDueDate),

      // Accounting details
      formatCurrencyForCSV(item.currentOutstandingLoanBalance),
      formatCurrencyForCSV(item.totalPaymentsMade),
      formatCurrencyForCSV(item.fortifyPlatformFee),
      formatPercent(item.fortifyPortfolioManagementFeeSplit),
      formatCurrencyForCSV(item.fortifyPlatformFeeReceived),
      formatCurrencyForCSV(item.fortifyPortfolioManagementFeeReceived),
      formatCurrencyForCSV(item.schoolTuitionReceived),
    ]);
  
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      const today = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
      link.setAttribute('download', `Fortify Forbearance Report - ${today}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (portfolioData.length === 0) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Forbearance</div>
        <hr className='my-2 line-divider'/>
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              You don't have any students in forbearance right now.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Forbearance</div>
        <ExportToCSV exportToCSV={exportToCSV}/>
      </div>
      <hr className='mt-2 line-divider'/>
      <div className="flex-grow overflow-hidden loan-table-wrapper">
        <div className="mt-6 loan-table-container">
          <table className="loan-table">
            <thead className="bg-gray-100 sticky top-0">
              <tr className="h-12">
                {/* Student details */}
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('studentName')}>
                  Student {renderSortIndicator('studentName')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('programName')}>
                  Program {renderSortIndicator('programName')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyTier')}>
                  Fortify Tier {renderSortIndicator('fortifyTier')}
                </th>

                {/* Forbearance details */}
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastForbearanceBeginDate')}>
                  Last Forbearance Start Date {renderSortIndicator('lastForbearanceBeginDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastForbearanceEndDate')}>
                  Last Forbearance End Date {renderSortIndicator('lastForbearanceEndDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('totalForbearanceMonths')}>
                  Total Forbearance Months {renderSortIndicator('totalForbearanceMonths')}
                </th>

                {/* Delinquency details */}
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('daysDelinquent')}>
                  Days Delinquent {renderSortIndicator('daysDelinquent')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('pastDueAmount')}>
                  Past Due Amount {renderSortIndicator('pastDueAmount')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('earliestOwedPaymentDate')}>
                  Earliest Owed Payment Date {renderSortIndicator('earliestOwedPaymentDate')}
                </th>

                {/* Repayment details */}
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('currentlyInSchool')}>
                  In School {renderSortIndicator('currentlyInSchool')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('gradDate')}>
                  Graduation Date {renderSortIndicator('gradDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('autopayStatus')}>
                  Autopay Enabled {renderSortIndicator('autopayStatus')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastLogin')}>
                  Logged into Payment Portal {renderSortIndicator('lastLogin')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastLogin')}>
                  Last Login To Payment Portal {renderSortIndicator('lastLogin')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastCallAttemptDate')}>
                  Last Call Attempt by ZuntaFi {renderSortIndicator('lastCallAttemptDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastCallDate')}>
                  Last Conversation with ZuntaFi {renderSortIndicator('lastCallDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('loanCounselingCompletedOn')}>
                  Loan Counseling Completed {renderSortIndicator('loanCounselingCompletedOn')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('loanCounselingCompletedOn')}>
                  Loan Counseling Completed On {renderSortIndicator('loanCounselingCompletedOn')}
                </th>

                {/* Contact details */}
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('email')}>
                  Email {renderSortIndicator('email')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('phone')}>
                  Phone {renderSortIndicator('phone')}
                </th>

                {/* Loan details */}
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('tuitionFinanced')}>
                  Tuition Financed {renderSortIndicator('tuitionFinanced')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('borrowingAmount')}>
                  Final Loan Amount {renderSortIndicator('borrowingAmount')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('interestRate')}>
                  Interest Rate {renderSortIndicator('interestRate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('loanTerm')}>
                  Loan Term {renderSortIndicator('loanTerm')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('monthlyPayment')}>
                  Monthly Payment {renderSortIndicator('monthlyPayment')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('originationScheduledFor')}>
                  Origination Scheduled For {renderSortIndicator('originationScheduledFor')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('originatedOn')}>
                  Originated On {renderSortIndicator('originatedOn')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('firstPaymentDueDate')}>
                  First Payment Due Date {renderSortIndicator('firstPaymentDueDate')}
                </th>

                {/* Accounting details */}
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('currentOutstandingLoanBalance')}>
                  Current Outstanding Loan Balance {renderSortIndicator('currentOutstandingLoanBalance')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('totalPaymentsMade')}>
                  Total Payments Made {renderSortIndicator('totalPaymentsMade')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPlatformFee')}>
                  Total Fortify Platform Fee {renderSortIndicator('fortifyPlatformFee')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPortfolioManagementFeeSplit')}>
                  Fortify Ongoing Fee {renderSortIndicator('fortifyPortfolioManagementFeeSplit')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPlatformFeeReceived')}>
                  Fortify Platform Fees Paid {renderSortIndicator('fortifyPlatformFeeReceived')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPortfolioManagementFeeReceived')}>
                  Fortify Ongoing Fees Paid {renderSortIndicator('fortifyPortfolioManagementFeeReceived')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('schoolTuitionReceived')}>
                  Tuition Collected {renderSortIndicator('schoolTuitionReceived')}
                </th>
              </tr>
            </thead>
            <tbody>
              {getSortedData(portfolioData).map((item, index) => (
                <tr key={index} className="border-b h-12 cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/loans/${item.loanId}`)}>
                  {/* Student details */}
                  <td className="py-2 px-4 whitespace-nowrap">{item.studentName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.programName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{getFortifyTierDisplay(item)}</td>

                  {/* Forbearance details */}
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastForbearanceBeginDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastForbearanceEndDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.totalForbearanceMonths}</td>

                  {/* Delinquency details */}
                  <td className="py-2 px-4 whitespace-nowrap">{item.daysDelinquent}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.pastDueAmount)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.earliestOwedPaymentDate)}</td>

                  {/* Repayment details */}
                  <td className="py-2 px-4 whitespace-nowrap">
                    <StatusPill text={item.currentlyInSchool ? "Yes" : "No"} backgroundColor={item.currentlyInSchool ? "bg-fortify-green" : "bg-gray-500"} />
                  </td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.gradDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">
                    <StatusPill text={item.autopayStatus === "Y" ? "Yes" : "No"} backgroundColor={(item.autopayStatus === "Y") ? "bg-fortify-green" : "bg-red-700"} />
                  </td>
                  <td className="py-2 px-4 whitespace-nowrap">
                    <StatusPill text={item.lastLogin !== null ? "Yes" : "No"} backgroundColor={(item.lastLogin !== null) ? "bg-fortify-green" : "bg-red-700"} />
                  </td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastLogin)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallAttemptDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">
                    <StatusPill text={item.loanCounselingCompletedOn !== null ? "Yes" : "No"} backgroundColor={(item.loanCounselingCompletedOn !== null) ? "bg-fortify-green" : "bg-red-700"} />
                  </td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.loanCounselingCompletedOn)}</td>

                  {/* Contact details */}
                  <td className="py-2 px-4 whitespace-nowrap">
                    <a href={`mailto:${item.email}`} className="no-underline hover:underline">
                      {item.email}
                    </a>
                  </td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatPhone(item.phone)}</td>

                  {/* Loan details */}
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.tuitionFinanced)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.borrowingAmount)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatPercentToTwoDecimals(item.interestRate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.loanTerm} months</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.monthlyPayment)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.originationScheduledFor)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.originatedOn)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.firstPaymentDueDate)}</td>

                  {/* Accounting details */}
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.currentOutstandingLoanBalance)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.totalPaymentsMade)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFee)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatPercent(item.fortifyPortfolioManagementFeeSplit)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFeeReceived)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPortfolioManagementFeeReceived)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.schoolTuitionReceived)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Forbearance;