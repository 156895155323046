import { useState } from 'react';

export const useTableSort = (initialField = null, initialDirection = null) => {
  const [sortField, setSortField] = useState(initialField);
  const [sortDirection, setSortDirection] = useState(initialDirection);

  const handleSort = (field) => {
    if (sortField === field) {
      if (sortDirection === "asc") {
        setSortDirection("desc");
      } else if (sortDirection === "desc") {
        setSortField(null);
        setSortDirection(null);
      }
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const getSortedData = (data) => {
    // Return early if data is not valid
    if (!data || !Array.isArray(data)) return [];
    if (!sortField || !sortDirection) return data;
    
    return [...data].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      // Handle special cases for fortifyTier
      if (sortField === 'fortifyTier') {
        // Move "?" to the end regardless of sort direction
        if (aValue === "?") return 1;
        if (bValue === "?") return -1;
        
        // Convert to numbers for comparison
        const aNum = Number(aValue);
        const bNum = Number(bValue);
        return sortDirection === "asc" ? aNum - bNum : bNum - aNum;
      }
      
      // For strings
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortDirection === "asc" 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      // For numbers and other types
      return sortDirection === "asc"
        ? aValue - bValue
        : bValue - aValue;
    });
  };

  const renderSortIndicator = (field) => {
    if (sortField !== field) return null;
    return sortDirection === "asc" ? "  ↓" : "  ↑";
  };

  return {
    sortField,
    sortDirection,
    handleSort,
    getSortedData,
    renderSortIndicator
  };
}; 