import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchLoanPortfolioForSchool } from '../../api/applications';
import { PortfolioReportData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrency, formatCurrencyForCSV, formatDate, formatDateForCSV, formatPercentToTwoDecimals, formatPercent } from '../../utils/formatters';
import StatusPill from '../shared/StatusPill';
import ExportToCSV from '../shared/ExportToCSV';
import { useTableSort } from '../../hooks/useTableSort';

const LoanPortfolio = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [portfolioData, setPortfolioData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { handleSort, getSortedData, renderSortIndicator } = useTableSort();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchLoanPortfolioForSchool(activeSchool.id);
        const formattedData = data.map(item => new PortfolioReportData(item));
        setPortfolioData(formattedData);
      } catch (error) {
        console.error("Error fetching loan portfolio data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!activeSchool.has_repayments_access) {
      navigate('/dashboard');
    } else {
      fetchData();
    }
  }, [activeSchool.id, navigate, activeSchool.has_repayments_access]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (portfolioData.length === 0) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Loan Portfolio</div>
        <hr className='my-2 line-divider'/>
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              There's no data here yet.<br/>Send your Fortify application link to more students to build your school's portfolio.
            </div>
          </div>
        </div>
      </div>
    );
  }

  const exportToCSV = () => {
    const headers = [
      "Student",
      "Program",
      "Fortify Tier",
      "Start Date",
      "Graduation Date",
      "Origination Scheduled For",
      "Originated On",
      "First Payment Due Date",
      "Earliest Owed Payment Date",
      "Tuition Financed",
      "Final Loan Amount",
      "Interest Rate",
      "Loan Term",
      "Monthly Payment",
      "Days Delinquent",
      "Past Due Amount",
      "Days Most Delinquent",
      "Last Forbearance Start Date",
      "Last Forbearance End Date",
      "Total Forbearance Months",
      "Autopay Enabled",
      "Registered For Payment Portal On",
      "Last Login To Payment Portal",
      "Last Call Attempt by ZuntaFi",
      "Last Conversation with ZuntaFi",
      "Loan Counseling Completed On",

      // Accounting details
      "Current Outstanding Loan Balance",
      "Total Payments Made",
      "Total Fortify Platform Fee",
      "Fortify Ongoing Fee",
      "Fortify Platform Fees Paid",
      "Fortify Ongoing Fees Paid",
      "Tuition Collected",
    ];
  
    const csvData = portfolioData.map(item => [
      item.studentName,
      item.programName,
      item.fortifyTier,
      formatDateForCSV(item.startDate),
      formatDateForCSV(item.gradDate),
      formatDateForCSV(item.originationScheduledFor),
      formatDateForCSV(item.originatedOn),
      formatDateForCSV(item.firstPaymentDueDate),
      formatDateForCSV(item.earliestOwedPaymentDate),
      formatCurrencyForCSV(item.tuitionFinanced),
      formatCurrencyForCSV(item.borrowingAmount),
      formatPercentToTwoDecimals(item.interestRate),
      item.loanTerm,
      formatCurrencyForCSV(item.monthlyPayment),
      item.daysDelinquent,
      formatCurrencyForCSV(item.pastDueAmount),
      item.daysMostDelinquent,
      formatDateForCSV(item.lastForbearanceBeginDate),
      formatDateForCSV(item.lastForbearanceEndDate),
      item.totalForbearanceMonths,
      item.autopayStatus === "Y" ? "Y" : "N",
      formatDateForCSV(item.registrationDate),
      formatDateForCSV(item.lastLogin),
      formatDateForCSV(item.lastCallAttemptDate),
      formatDateForCSV(item.lastCallDate),
      formatDateForCSV(item.loanCounselingCompletedOn),

      // Accounting details
      formatCurrencyForCSV(item.currentOutstandingLoanBalance),
      formatCurrencyForCSV(item.totalPaymentsMade),
      formatCurrencyForCSV(item.fortifyPlatformFee),
      formatPercent(item.fortifyPortfolioManagementFeeSplit),
      formatCurrencyForCSV(item.fortifyPlatformFeeReceived),
      formatCurrencyForCSV(item.fortifyPortfolioManagementFeeReceived),
      formatCurrencyForCSV(item.schoolTuitionReceived),
    ]);
  
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      const today = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
      link.setAttribute('download', `Fortify Loan Portfolio Report - ${today}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Loan Portfolio</div>
        <ExportToCSV exportToCSV={exportToCSV}/>
      </div>
      <hr className='mt-2 line-divider'/>
      <div className="flex-grow overflow-hidden loan-table-wrapper">
        <div className="mt-6 loan-table-container">
          <table className="loan-table">
            <thead className="bg-gray-100 sticky top-0">
              <tr className="h-12">
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('studentName')}>
                  Student {renderSortIndicator('studentName')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('programName')}>
                  Program {renderSortIndicator('programName')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyTier')}>
                  Fortify Tier {renderSortIndicator('fortifyTier')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('startDate')}>
                  Start Date {renderSortIndicator('startDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('gradDate')}>
                  Graduation Date {renderSortIndicator('gradDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('originationScheduledFor')}>
                  Origination Scheduled For {renderSortIndicator('originationScheduledFor')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('originatedOn')}>
                  Originated On {renderSortIndicator('originatedOn')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('firstPaymentDueDate')}>
                  First Payment Due Date {renderSortIndicator('firstPaymentDueDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('earliestOwedPaymentDate')}>
                  Earliest Owed Payment Date {renderSortIndicator('earliestOwedPaymentDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('tuitionFinanced')}>
                  Tuition Financed {renderSortIndicator('tuitionFinanced')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('borrowingAmount')}>
                  Final Loan Amount {renderSortIndicator('borrowingAmount')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('interestRate')}>
                  Interest Rate {renderSortIndicator('interestRate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('loanTerm')}>
                  Loan Term {renderSortIndicator('loanTerm')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('monthlyPayment')}>
                  Monthly Payment {renderSortIndicator('monthlyPayment')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('daysDelinquent')}>
                  Days Delinquent {renderSortIndicator('daysDelinquent')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('pastDueAmount')}>
                  Past Due Amount {renderSortIndicator('pastDueAmount')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('daysMostDelinquent')}>
                  Days Most Delinquent {renderSortIndicator('daysMostDelinquent')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastForbearanceBeginDate')}>
                  Last Forbearance Start Date {renderSortIndicator('lastForbearanceBeginDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastForbearanceEndDate')}>
                  Last Forbearance End Date {renderSortIndicator('lastForbearanceEndDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('totalForbearanceMonths')}>
                  Total Forbearance Months {renderSortIndicator('totalForbearanceMonths')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('autopayStatus')}>
                  Autopay Enabled {renderSortIndicator('autopayStatus')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('registrationDate')}>
                  Registered For Payment Portal On {renderSortIndicator('registrationDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastLogin')}>
                  Last Login To Payment Portal {renderSortIndicator('lastLogin')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastCallAttemptDate')}>
                  Last Call Attempt by ZuntaFi {renderSortIndicator('lastCallAttemptDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('lastCallDate')}>
                  Last Conversation with ZuntaFi {renderSortIndicator('lastCallDate')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('loanCounselingCompletedOn')}>
                  Loan Counseling Completed On {renderSortIndicator('loanCounselingCompletedOn')}
                </th>

                {/* Accounting details */}
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('currentOutstandingLoanBalance')}>
                  Current Outstanding Loan Balance {renderSortIndicator('currentOutstandingLoanBalance')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('totalPaymentsMade')}>
                  Total Payments Made {renderSortIndicator('totalPaymentsMade')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPlatformFee')}>
                  Total Fortify Platform Fee {renderSortIndicator('fortifyPlatformFee')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPortfolioManagementFeeSplit')}>
                  Fortify Ongoing Fee {renderSortIndicator('fortifyPortfolioManagementFeeSplit')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPlatformFeeReceived')}>
                  Fortify Platform Fees Paid {renderSortIndicator('fortifyPlatformFeeReceived')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('fortifyPortfolioManagementFeeReceived')}>
                  Fortify Ongoing Fees Paid {renderSortIndicator('fortifyPortfolioManagementFeeReceived')}
                </th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap cursor-pointer" onClick={() => handleSort('schoolTuitionReceived')}>
                  Tuition Collected {renderSortIndicator('schoolTuitionReceived')}
                </th>
              </tr>
            </thead>
            <tbody>
              {getSortedData(portfolioData).map((item, index) => (
                <tr key={index} className="border-b h-12 cursor-pointer hover:bg-gray-100" onClick={() => navigate(`/loans/${item.loanId}`)}>
                  <td className="py-2 px-4 whitespace-nowrap">{item.studentName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.programName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.fortifyTier}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.startDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.gradDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.originationScheduledFor)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.originatedOn)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.firstPaymentDueDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.earliestOwedPaymentDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.tuitionFinanced)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.borrowingAmount)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatPercentToTwoDecimals(item.interestRate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.loanTerm} months</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.monthlyPayment)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.daysDelinquent}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.pastDueAmount)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.daysMostDelinquent}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastForbearanceBeginDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastForbearanceEndDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.totalForbearanceMonths}</td>
                  <td className="py-2 px-4 whitespace-nowrap">
                    <StatusPill text={item.autopayStatus === "Y" ? "Yes" : "No"} backgroundColor={(item.autopayStatus === "Y") ? "bg-green-700" : "bg-red-700"} />
                  </td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.registrationDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastLogin)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallAttemptDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.loanCounselingCompletedOn)}</td>

                  {/* Accounting details */}
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.currentOutstandingLoanBalance)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.totalPaymentsMade)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFee)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatPercent(item.fortifyPortfolioManagementFeeSplit)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFeeReceived)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPortfolioManagementFeeReceived)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.schoolTuitionReceived)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LoanPortfolio;
